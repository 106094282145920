import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const ESO1 = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview" 
            description={`
                <p>The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;590, a multicenter, randomized, placebo-controlled trial that enrolled 749 patients with metastatic or locally advanced esophageal or gastroesophageal junction (tumors with epicenter 1 to 5 centimeters above the GEJ) carcinoma who were not candidates for surgical resection or definitive chemoradiation. PD&#8288;-&#8288;L1 status was centrally determined in tumor specimens in all patients using the PD&#8288;-&#8288;L1 IHC 22C3 pharmDx kit. Patients with active autoimmune disease, a medical condition that required immunosuppression, or who received prior systemic therapy in the locally advanced or metastatic setting were ineligible. Randomization was stratified by tumor histology (squamous cell carcinoma vs adenocarcinoma), geographic region (Asia vs ex-Asia), and ECOG performance status (0 vs 1).</p>
                <p>Patients were randomized (1:1) to one of the following treatment arms; all study medications were administered via IV infusion:</p>
                <ul>
                    <li>KEYTRUDA 200&nbsp;mg on Day 1 of each 3-week cycle in combination with cisplatin 80&nbsp;mg/m<sup>2</sup> IV on Day 1 of each 3-week cycle for up to 6 cycles and FU 800&nbsp;mg/m<sup>2</sup> IV per day on Day 1 to Day 5 of each 3-week cycle, or per local standard for FU administration, for up to 24 months.</li>
                    <li>Placebo on Day 1 of each 3-week cycle in combination with cisplatin 80&nbsp;mg/m<sup>2</sup> IV on Day 1 of each 3-week cycle for up to 6 cycles and FU 800&nbsp;mg/m<sup>2</sup> IV per day on Day 1 to Day 5 of each 3-week cycle, or per local standard for FU administration, for up to 24 months.</li>
                </ul>
                <p>Treatment with KEYTRUDA or chemotherapy continued until unacceptable toxicity or disease progression. Patients could be treated with KEYTRUDA for up to 24 months in the absence of disease progression. The major efficacy outcome measures were OS and PFS as assessed by the investigator according to RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ). The study prespecified analyses of OS and PFS based on squamous cell histology, CPS ≥10, and in all patients. Additional efficacy outcome measures were ORR and DOR, according to modified RECIST v1.1, as assessed by the investigator.</p>
                <p>The study population characteristics were: median age of 63 years (range: 27 to 94), 43% age 65 or older; 83% male; 37% White, 53% Asian, and 1% Black; 40% had an ECOG PS of 0 and 60% had an ECOG PS of 1. Ninety-one percent had M1 disease and 9% had M0 disease. Seventy-three percent had a tumor histology of squamous cell carcinoma, and 27% had adenocarcinoma.</p>
                `}
            definitions={`
                IV = intravenous; IHC = immunohistochemistry; ECOG PS = Eastern Cooperative Oncology Group performance status; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors.
            `}  
        />
    </ComponentWrapper>
);

export default ESO1