import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, SecondaryCallout, KMCurve, ComparisonTable, EndpointDisplay, ReferencesBlock ,TextBlock} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import * as colors from '../../util/colors';
import ESO1 from '../../components/StudyDesign/__study-design/eso1'
import ESO2 from '../../components/StudyDesign/__study-design/eso2'
import ESO3 from '../../components/StudyDesign/__study-design/eso3'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-590, KEYNOTE-180 & KEYNOTE-181 - Clinical Trial Results | HCP`,
    keywords: `keynote 590, keynote 180, keynote 181`,
    description: `Health care professionals can find clinical trial results for KEYNOTE-590, KEYNOTE-180, and KEYNOTE-181 in patients with advanced esophageal carcinoma or gej cancer.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/esophageal-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠590","description":"1L Combination Therapy Clinical Findings from KEYNOTE⁠-⁠590","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠590, a multicenter, randomized, placebo-controlled trial that enrolled 749 patients with metastatic or locally advanced esophageal or gastroesophageal junction (tumors with epicenter 1 to 5 centimeters above the GEJ) carcinoma who were not candidates for surgical resection or definitive chemoradiation."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/esophageal-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠181","description":"Study Design for KEYNOTE⁠-⁠181","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠181, a multicenter, randomized, open-label, active-controlled trial that enrolled 628 patients with recurrent locally advanced or metastatic esophageal cancer who progressed on or after 1 prior line of systemic treatment for advanced disease."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/esophageal-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠180","description":"Clinical Findings from KEYNOTE⁠-⁠180","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠180, a multicenter, nonrandomized, open-label trial that enrolled 121 patients with locally advanced or metastatic esophageal cancer who progressed on or after at least 2 prior systemic treatments for advanced disease. With the exception of the number of prior lines of treatment, the eligibility criteria were similar to and the dosage regimen identical to KEYNOTE⁠-⁠181."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/esophageal-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 19;
const jobCode = jobCodes[19].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/esophageal-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: '1L Combination Therapy Clinical Findings from KEYNOTE&#8288;-&#8288;590' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;590' },
    { label: '2L or Greater Monotherapy Clinical Findings from KEYNOTE&#8288;-&#8288;180 and KEYNOTE&#8288;-&#8288;181' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;181' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;180' }
]

const keynoteFiveNineOhFootnotes = [
    {
        label: "c.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "d.",
        text: "Based on a stratified log-rank test."
    },
    {
        label: "e.",
        text: "The study prespecified analyses of OS and progression-free survival based on squamous cell histology, CPS ≥10, and in all patients."
    },
]

const keynoteOneEightOneFootnotes = [
    {
        label: "j.",
        text: `Based on the Cox regression model stratified by geographic region (Asia vs ex-Asia).`
    },
]

const overallSurvivalFiveNineOhTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA + cisplatin/FU (n=373)',
            },
            {
                legendTitle: 'Placebo + cisplatin/FU (n=376)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                colspan: 2,
                centerText: true,
                hrCiP: 'HR<sup>a</sup>=0.73; 95% CI, 0.62–0.86; <i>P</i><sup>b</sup><0.0001',
            }
        ],
        [
            {
                label: 'Events Observed',
                dataPoint: '70%',
                dataSubPoint: '(n=262/373)',
            },
            {
                label: 'Events Observed',
                dataPoint: '82%',
                dataSubPoint: '(n=309/376)',
            },
        ],
        [
            {
                label: 'Median OS',
                dataPoint: '12.4 months',
                dataSubPoint: '(95% CI, 10.5–14.0)',
            },
            {
                label: 'Median OS',
                dataPoint: '9.8 months',
                dataSubPoint: '(95% CI, 8.8–10.8)',
            },
        ],
    ],


    footnotes: [
        {
            label: "a.",
            text: "Based on the stratified Cox proportional hazard model."
        },
        {
            label: "b.",
            text: "Based on a stratified log-rank test."
        },
    ],
    definitions: "1L = first line; PD&#8288;-&#8288;L1 = programmed death ligand 1; FU = fluorouracil;  HR = hazard ratio; CI = confidence interval; CPS = combined positive score."
}

const progressionSurvivalPatientTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA + cisplatin/FU',
            },
            {
                legendTitle: 'Placebo + cisplatin/FU',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                hrCiP: 'HR=0.62; 95% CI, 0.49&ndash;0.78; <i>P</i><0.0001',
                centerText: true,
                colspan: 2,
            }
        ],
        [
            {
                label: 'Median OS',
                dataPoint: '13.5 months',
                dataSubPoint: '(95% CI, 11.1–15.6)',
            },
            {
                label: 'Median OS',
                dataPoint: '9.4 months',
                dataSubPoint: '(95% CI, 8.0–10.7)',
            },
        ],
    ],
}

const progressionFreeSurvivalTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA + cisplatin/FU (n=373)',
            },
            {
                legendTitle: 'Placebo + cisplatin/FU (n=376)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                colspan: 2,
                centerText: true,
                hrCiP: 'HR<sup>f</sup>=0.65; 95% CI, 0.55–0.76; <i>P</i><sup>g</sup><0.0001',
            }
        ],
        [
            {
                label: 'Median PFS',
                dataPoint: '6.3 months',
                dataSubPoint: '(95% CI, 6.2–6.9)',
            },
            {
                label: 'Median PFS',
                dataPoint: '5.8 months',
                dataSubPoint: '(95% CI, 5.0–6.0)',
            },
        ],
        [
            {
                label: 'Events Observed',
                dataPoint: '80%',
                dataSubPoint: '(n=297/373)',
            },
            {
                label: 'Events Observed',
                dataPoint: '89%',
                dataSubPoint: '(n=333/376)',
            },
        ],
    ],
    footnotes: [
        {
            label: "f.",
            text: "Based on the stratified Cox proportional hazard model."
        },
        {
            label: "g.",
            text: "Based on a stratified log-rank test."
        },
    ],
}

const durationOfResponseTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA + cisplatin/FU (n=373)',
            },
            {
                legendTitle: 'Placebo + cisplatin/FU (n=376)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                label: 'Median DOR',
                dataPoint: '8.3 months',
                dataSubPoint: '(range: 1.2+ to 31.0+ months)',
            },
            {
                label: 'Median DOR',
                dataPoint: '6.0 months',
                dataSubPoint: '(range: 1.5+ to 25.0+ months)',
            },
        ],
    ],
    definitions: "+ Denotes ongoing response."
};

const overallSurvivalOneEightOneTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA (n=85)',
            },
            {
                legendTitle: 'Chemotherapy (n=82)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                colspan: 2,
                centerText: true,
                hrCiP: 'HR<sup>k</sup>=0.64; 95% CI, 0.46–0.90',
            }
        ],
        [
            {
                label: 'Events Observed',
                dataPoint: '80%',
                dataSubPoint: '(n=68/85)',
            },
            {
                label: 'Events Observed',
                dataPoint: '88%',
                dataSubPoint: '(n=72/82)',
            },
        ],
        [
            {
                label: 'Median OS',
                dataPoint: '10.3 months',
                dataSubPoint: '(95% CI, 7.0–13.5)',
            },
            {
                label: 'Median OS',
                dataPoint: '6.7 months',
                dataSubPoint: '(95% CI, 4.8–8.6)',
            },
        ],
    ],
    footnotes: [
        {
            label: "k.",
            text: "Based on the Cox regression model stratified by geographic region (Asia vs ex-Asia)."
        },
    ],
}

const progressiveFreeSurvivalOneEightOneTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA (n=85)',
            },
            {
                legendTitle: 'Chemotherapy (n=82)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                colspan: 2,
                centerText: true,
                hrCiP: 'HR=0.66; 95% CI, 0.48–0.92<sup>l</sup>',
            }
        ],
        [
            {
                label: 'Median PFS',
                dataPoint: '3.2 months',
                dataSubPoint: '(95% CI, 2.1–4.4)',
            },
            {
                label: 'Median PFS',
                dataPoint: '2.3 months',
                dataSubPoint: '(95% CI, 2.1–3.4)',
            },
        ],
        [
            {
                label: 'Events observed',
                dataPoint: '89%',
                dataSubPoint: '(n=76)',
            },
            {
                label: 'Events observed',
                dataPoint: '93%',
                dataSubPoint: '(n=76)',
            },
        ],
    ],
    footnotes: [
        {
            label: "l.",
            text: "Based on the Cox regression model stratified by geographic region (Asia vs ex-Asia)."
        },
    ],
}

const ObjectiveResponsRatelOneEightOneTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA (n=85)',
            },
            {
                legendTitle: 'Chemotherapy (n=82)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                label: 'ORR',
                dataPoint: '22%',
                dataSubPoint: '(95% CI, 14–33)',
            },
            {
                label: 'ORR',
                dataPoint: '7%',
                dataSubPoint: '(95% CI, 3–15)',
            },
        ],
        [
            {
                label: 'Complete Response',
                dataPoint: '5%',
                dataSubPoint: '(n=4)'
            },
            {
                label: 'Complete Response',
                dataPoint: '1%',
                dataSubPoint: '(n=1)'
            },
        ],
        [
            {
                label: 'Partial Response',
                dataPoint: '18%',
                dataSubPoint: '(n=15)'
            },
            {
                label: 'Partial Response',
                dataPoint: '6%',
                dataSubPoint: '(n=5)'
            },
        ],
    ]
}
const ORRendpointDataFootnotes = [
    {
        label: 'h.',
        text: 'Based on the stratified Miettinen and Nurminen method.'
    },
    {
        label: 'i.',
        text: 'Confirmed complete response or partial response.'
    }
]

const DOR181ComparisonTableData = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA (n=85)',
            },
            {
                legendTitle: 'Chemotherapy (n=82)',
                legendSwatchColor: colors.concrete,
            },
        ],
        [
            {
                label: 'Median DOR',
                dataPoint: '9.3 months',
                dataSubPoint: '(range: 2.1+ – 18.8+ months)',
            },
            {
                label: 'Median DOR',
                dataPoint: '7.7 months',
                dataSubPoint: '(range: 4.3–16.8+ months)',
            },
        ]
    ],
    definitions: "+ Denotes ongoing response."
}

const key180ORRTableData = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA 200&nbsp;mg/kg Q3W (n=35)',
            },
        ],
        [
            {
                label: 'ORR',
                dataPoint: '20%',
                dataSubPoint: '(95% CI, 8–37)',
            },
        ],
    ],
};

const key180DORTableData = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA 200&nbsp;mg/kg Q3W (n=35)',
            },
        ],
        [
            {
                label: 'DOR among 7 responding patients (in months)',
                dataPoint: '(range: 4.2–25.1+ months)',
            },
        ],
        [
            {
                label: 'Patients with responses lasting ≥6 months',
                dataPoint: '71%',
                dataSubPoint: '(n=5)',
            },
        ],
        [
            {
                label: 'Patients with responses lasting ≥12 months',
                dataPoint: '57%',
                dataSubPoint: '(n=3)',
            },
        ],
    ],
    definitions: "+ Denotes ongoing response; Q3W = every 3 weeks."
};

const pageReferences = [
    {
        label: "1.",
        text: "Sun JM, Shen L, Shah MA, et al. Pembrolizumab plus chemotherapy versus chemotherapy alone for first-line treatment of advanced oesophageal cancer (KEYNOTE-590): a randomised, placebo-controlled, phase 3 study. <i>Lancet.</i> 2021;398(10302):759-771."
    }
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection title={anchorLinkModelData[0].label} bgColor="cloud">
                            <SecondaryCallout
                                title="KEYNOTE&#8288;-&#8288;590: Superior overall survival (OS) with KEYTRUDA + cisplatin/FU vs cisplatin/FU alone in advanced esophageal carcinoma. The study included patients with adenocarcinoma or squamous cell carcinoma, with or without PD&#8288;-&#8288;L1 expression"
                                alt=""
                            />
                            
                            <PageSubSection title="All Patients (N=749)">       

                            <SecondaryCallout
                                title="OS: 27% reduction in the risk of death with KEYTRUDA + cisplatin/FU vs cisplatin/FU alone"
                                text="HR<sup>a</sup>=0.73; 95% CI, 0.62–0.86; <i>P</i><sup>b</sup><0.0001"
                                alt=""
                            />

                            <ComparisonTable
                                title="KEYNOTE&#8288;-&#8288;590: Overall Survival in 1L Combination Therapy" {...overallSurvivalFiveNineOhTable}
                            />
                            </PageSubSection>

                            <PageSubSection title="PD&#8288;-&#8288;L1 CPS ≥10  Population (n=383)">       
                                <TextBlock>
                                    <p>Superior overall survival in first-line treatment with KEYTRUDA + chemotherapy vs chemotherapy alone (PD-L1 CPS ≥10 population). </p>
                                    <p>In a prespecified formal test of overall survival in patients with CPS ≥10, including those with adenocarcinoma or squamous cell carcinoma</p>
                                </TextBlock>
                            <KMCurve
                                title="Kaplan-Meier Estimates of OS with KEYTRUDA + Cisplatin/FU vs Cisplatin/FU Alone in Patients with PD&#8288;-&#8288;L1 CPS ≥10<sup>1</sup>"
                                image={() => <StaticImage 
                                    placeholder="blurred"
                                    src="../../assets/KEYNOTE-590_KM_Curve-v-2.png"
                                    alt="KEYNOTE-590: Kaplan-Meier Estimates of OS With KEYTRUDA® (pembrolizumab) + Cisplatin/FU vs Cisplatin/FU Alone in Patients With PD-L1 CPS ≥10" />}
                                hr="HR<sup>c</sup>=0.62; (95% CI, 0.49–0.78; <i>P</i><sup>d</sup><0.0001)<sup>e</sup>"
                                footnotes={keynoteFiveNineOhFootnotes}
                            />
                            
                            <SecondaryCallout
                                title="OS: 38% reduction in the risk of death with KEYTRUDA + cisplatin/FU vs cisplatin/FU alone in patients with PD&#8288;-&#8288;L1 CPS ≥10"
                                alt=""
                            />
                            <ComparisonTable
                                title="Overall Survival for Patients with PD&#8288;-&#8288;L1 CPS ≥10" {...progressionSurvivalPatientTable}
                                    additionalInformation={[
                                        {
                                            title: "Additional Information",
                                            text: "In an exploratory analysis, in patients with PD&#8288;-&#8288;L1 CPS <10 (n=347), the median OS was 10.5 months (95% CI, 9.7—13.5) for the KEYTRUDA arm and 10.6 months (95% CI, 8.8—12.0) for the placebo arm, with an HR of 0.86 (95% CI, 0.68—1.10)"
                                        },
                                    ]}
                            />

                            </PageSubSection>
                            <PageSubSection title="All Patients (N=749)">
                            <SecondaryCallout
                                title="PFS: 35% reduction in the risk of disease progression or death with KEYTRUDA + cisplatin/FU vs cisplatin/FU alone"
                                alt=""
                            />
                            <ComparisonTable
                                title="Progression-Free Survival (PFS)" {...progressionFreeSurvivalTable}
                            />
                            <EndpointDisplay
                                title="Objective Response Rate (ORR)"
                                limitation="In 373 patients with KEYTRUDA + cisplatin/FU vs in 376 patients with placebo + cisplatin/FU (<i>P</i><sup>h</sup><0.0001)"
                                footnotes={ORRendpointDataFootnotes}
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA + cisplatin/FU',
                                            rate: '45%<sup>i</sup>',
                                            ci: '95% CI, 40–50',
                                            completeResponseRate: '6%',
                                            completeResponseCI: 'n=24',
                                            partialResponseRate: '39%',
                                            partialResponseCI: 'n=144'
                                        },
                                        {
                                            dataType: 'ORR',
                                            label: 'Placebo + cisplatin/FU',
                                            rate: '29%<sup>i</sup>',
                                            ci: '95% CI, 25–34',
                                            completeResponseRate: '2.4%',
                                            completeResponseCI: 'n=9',
                                            partialResponseRate: '27%',
                                            partialResponseCI: 'n=101'
                                        }
                                    ]
                                }
                            />
                            <ComparisonTable
                                title="Duration of Response (DOR)" {...durationOfResponseTable}
                            />
                            </PageSubSection>
                            <PageSubSection title={anchorLinkModelData[1].label} >
                                <ESO1 />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <SecondaryCallout 
                                title="First anti–programmed death receptor&#8288;-&#8288;1 (PD&#8288;-&#8288;1) approved in advanced esophageal squamous cell carcinoma (ESCC) (CPS ≥10)"
                                alt=""
                            />
                            <KMCurve
                                title="KEYNOTE&#8288;-&#8288;181: Kaplan-Meier Estimates of Overall Survival (OS) (ESCC CPS ≥10)"
                                limitation="The observed OS hazard ratio in KEYNOTE&#8288;-&#8288;181 was 0.77 (95% CI, 0.63–0.96) in patients with ESCC, 0.70 (95% CI, 0.52–0.94) in patients with tumors expressing PD&#8288;-&#8288;L1 CPS ≥10, and 0.89 (95% CI, 0.75–1.05) in all randomized patients. On further examination in patients whose ESCC tumors expressed PD&#8288;-&#8288;L1 (CPS ≥10), an improvement in OS was observed among patients randomized to KEYTRUDA as compared with chemotherapy."
                                image={() => <StaticImage 
                                    placeholder="blurred"
                                    src="../../assets/eso-181-km-v-2.png"
                                    alt="KEYNOTE⁠-⁠181: Kaplan-Meier Estimates of Overall Survival (OS) (ESCC CPS ≥10) With KEYTRUDA® (pembrolizumab)" />}
                                hr="HR<sup>j</sup>=0.64; 95% CI, 0.46–0.90"
                                footnotes={keynoteOneEightOneFootnotes}
                                definitions="2L = second line."
                            />
                            <ComparisonTable
                                title="KEYNOTE&#8288;-&#8288;181: Overall Survival (OS) (ESCC CPS ≥10)" {...overallSurvivalOneEightOneTable}
                            />
                            <ComparisonTable
                                title="KEYNOTE&#8288;-&#8288;181: Objective Response Rate (ORR) (ESCC CPS ≥10)" {...ObjectiveResponsRatelOneEightOneTable}
                            />
                            <ComparisonTable
                                title="KEYNOTE&#8288;-&#8288;181: Progression-Free Survival (PFS) (ESCC CPS ≥10)" {...progressiveFreeSurvivalOneEightOneTable}
                            />
                            <ComparisonTable
                                title="KEYNOTE&#8288;-&#8288;181: Duration of Response (DOR) (ESCC CPS ≥10)" {...DOR181ComparisonTableData}
                            />

                            <PageSubSection title={anchorLinkModelData[3].label}>
                                <ESO2 />
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="cloud" title="Clinical Findings from KEYNOTE&#8288;-&#8288;180">
                            <ComparisonTable
                                title="Objective Response Rate (ORR) (ESCC CPS ≥10)" {...key180ORRTableData}
                            />
                            <ComparisonTable
                                title="Duration of Response (DOR) (ESCC CPS ≥10)" {...key180DORTableData}
                            />

                            <PageSubSection title={anchorLinkModelData[4].label}>
                                <ESO3 />
                            </PageSubSection>

                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>

                    
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
