import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const ESO2 = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview" 
            description={`
                <p>The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;181, a multicenter, randomized, open-label, active-controlled trial that enrolled 628 patients with recurrent locally advanced or metastatic esophageal cancer who progressed on or after 1 prior line of systemic treatment for advanced disease. Patients with HER2/neu-positive esophageal cancer were required to have received treatment with approved HER2/neu-targeted therapy. All patients were required to have tumor specimens for PD&#8288;-&#8288;L1 testing at a central laboratory; PD&#8288;-&#8288;L1 status was determined using the PD&#8288;-&#8288;L1 IHC 22C3 pharmDx kit. Patients with a history of noninfectious pneumonitis that required steroids or current pneumonitis, active autoimmune disease, or a medical condition that required immunosuppression were ineligible.</p>
                <p>Patients were randomized (1:1) to receive either KEYTRUDA 200&nbsp;mg every 3 weeks or investigator’s choice of any of the following chemotherapy regimens, all given intravenously: paclitaxel 80-100&nbsp;mg/m<sup>2</sup> on Days 1, 8, and 15 of every 4-week cycle, docetaxel 75&nbsp;mg/m<sup>2</sup> every 3 weeks, or irinotecan 180&nbsp;mg/m<sup>2</sup> every 2 weeks. Randomization was stratified by tumor histology (esophageal squamous cell carcinoma [ESCC] vs esophageal adenocarcinoma [EAC]/Siewert type I EAC of the gastroesophageal junction [GEJ]), and geographic region (Asia vs ex-Asia). Treatment with KEYTRUDA or chemotherapy continued until unacceptable toxicity or disease progression. Patients randomized to KEYTRUDA were permitted to continue beyond the first RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ)-defined disease progression if clinically stable until the first radiographic evidence of disease progression was confirmed at least 4 weeks later with repeat imaging. Patients treated with KEYTRUDA without disease progression could be treated for up to 24 months. Assessment of tumor status was performed every 9 weeks. The major efficacy outcome measure was OS evaluated in the following co-primary populations: patients with ESCC, patients with tumors expressing PD&#8288;-&#8288;L1 CPS ≥10, and all randomized patients. Additional efficacy outcome measures were PFS, ORR, and DOR, according to RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ) as assessed by blinded independent central review (BICR).</p>
                <p>A total of 628 patients were enrolled and randomized to KEYTRUDA (n=314) or investigator’s treatment of choice (n=314). Of these 628 patients, 167 (27%) had ESCC that expressed PD&#8288;-&#8288;L1 with a CPS ≥10. Of these 167 patients, 85 patients were randomized to KEYTRUDA and 82 patients to investigator’s treatment of choice [paclitaxel (n=50), docetaxel (n=19), or irinotecan (n=13)]. The baseline characteristics of these 167 patients were: median age of 65 years (range: 33 to 80), 51% age 65 or older; 84% male; 32% White and 68% Asian; 38% had an ECOG PS of 0 and 62% had an ECOG PS of 1. Ninety percent had M1 disease and 10% had M0 disease. Prior to enrollment, 99% of patients had received platinum-based treatment and 84% had also received treatment with a fluoropyrimidine. Thirty-three percent of patients received prior treatment with a taxane.</p>
            `} 
            definitions={`
                HER2/neu = human epidermal growth factor receptor 2.
            `}
        />
    </ComponentWrapper>
);

export default ESO2

