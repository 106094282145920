import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const ESO3 = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview" 
            description={`
            <p>The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;180, a multicenter, nonrandomized, open-label trial that enrolled 121 patients with locally advanced or metastatic esophageal cancer who progressed on or after at least 2 prior systemic treatments for advanced disease. With the exception of the number of prior lines of treatment, the eligibility criteria were similar to and the dosage regimen identical to KEYNOTE&#8288;-&#8288;181.</p>
            <p>The major efficacy outcome measures were ORR and DOR according to RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ) as assessed by blinded independent central review (BICR).</p>
            <p>Among the 121 patients enrolled, 29% (n=35) had ESCC that expressed PD&#8288;-&#8288;L1 CPS ≥10. The baseline characteristics of these 35 patients were: median age of 65 years (range: 47 to 81), 51% age 65 or older; 71% male; 26% White and 69% Asian; 40% had an ECOG PS of 0 and 60% had an ECOG PS of 1. One hundred percent had M1 disease.</p>`
        } 
        />
    </ComponentWrapper>
);

export default ESO3